import queryString from 'query-string'
import { storeToRefs } from 'pinia'
import { useLobbyGameApi } from '@/api/lobby-game'
import { PATH_GAME_URL } from '~/constants/path'
import { EMPTY_CONTENT } from '~/constants/lobby'
import { useGameStore } from '~~/store/game'
import { useLoading } from '~/composables/useLoading'
import { IItemCongGame, IProvider } from '~/types/lobby.type'
import { PAGE_URLS } from '~/config/page-url'
export const useCasino = () => {
  const { $device, $pinia, $axios } = useNuxtApp()
  const store = useGameStore($pinia)
  const { LOBBY_CATEGORY, LIST_LOBBY_CASINO } = useLobbyGameApi()
  const route = useRoute()
  const router = useRouter()
  const { loading, load, isLoading } = useLoading()
  const { currentRoute, resultProvider, providerCasino } = storeToRefs(store)

  const listCasino = ref<IItemCongGame[] | null>(null)
  const listProvider = ref<IProvider[] | null>(null)
  const titleCasino = 'LIVE CASINO'
  const allObject = {
    code: 'all',
    name: 'Tất cả',
    url: '',
    image_path: `${PATH_GAME_URL}icon-all-mb.webp`
  }

  const providerMapping: [any: string] = {
    go: 'hit'
  }
  const reversedMapping = Object.fromEntries(
    Object.entries(providerMapping).map(([key, value]) => [value, key])
  )

  const currentProvider = ref<IProvider | null>(allObject)
  const currentCategory = ref<IItemCongGame | null>(null)
  const totalItemPerPage = computed(() => listCasino?.value?.length || 0)
  const typeQuery = computed(() => route.params?.type as string)
  const partnerQuery = computed(() => route.query?.partner as string)
  const listCategoryCasino = ref<IItemCongGame[]>([])
  const LIMIT: number = 30
  const page = ref<number>(1)
  const totalItem = ref<number>(0)
  const placeholderCount = ref<number>(0)

  const isLoadMore = computed(() => {
    return totalItemPerPage.value < totalItem.value
  })

  const initLoadData = async () => {
    page.value = 1
    await Promise.allSettled([fetchListCasino()])
    if (providerCasino.value) {
      filterProvider(providerCasino.value)
      currentProvider.value =
        listProvider.value?.find((item: IProvider) => item?.code === partnerQuery.value) ||
        (listProvider.value ? listProvider.value[0] : null)
    }
    currentCategory.value =
      providerCasino.value.find((item: IItemCongGame) => item?.alias === typeQuery.value) || providerCasino.value[0]
  }
  const handleChangeCategory = (item: IItemCongGame) => {
    currentCategory.value = item
    if (item?.alias) {
      navigateTo(`${PAGE_URLS.CASINO}/${item?.alias}`)
    }
  }
  const handleChangeProvider = (item: IProvider) => {
    currentProvider.value = item
    if (item?.code) {
      const providerType = providerMapping[item?.code] || item?.code
      navigateTo({
        query: { ...route.query, partner: providerType }
      })
    }
  }
  const filterProvider = (data: IItemCongGame[]) => {
    const type = route?.params?.type as string
    const filteredItem = data.find((item) => {
      if (type) {
        return item?.alias === type
      }
      return item?.alias === 'all'
    })

    listProvider.value = filteredItem?.providers || []
    const isAllExists = listProvider.value.some((item) => item?.code === 'all')
    if (!isAllExists) {
      listProvider.value.unshift(allObject)
    }
  }
  const fetchListCasino = async (params: { type?: string; partner?: string; limit?: number } = {}, $state?: any) => {
    loading()
    const gameType = route?.params?.type
    const partnerGameType = typeQuery.value
    let partnerProvider = params?.partner || partnerQuery.value
    partnerProvider = reversedMapping[partnerProvider] || partnerProvider
    const limitParam = params?.limit || LIMIT
    const queryUrl = queryString.stringify({
      path_alias: gameType,
      provider_code: partnerProvider && partnerProvider !== 'all' ? partnerProvider : '',
      limit: limitParam,
      page: page.value
    })
    try {
      const { data: response } = await $axios.get(`${LIST_LOBBY_CASINO}?${queryUrl}`)
      if (response.status === 'OK') {
        if (page.value > 1 && listCasino.value) {
          listCasino.value = listCasino.value.concat(response.data?.items)
        } else {
          listCasino.value = response.data?.items
        }
        store.setGameLists(listCasino.value)
        totalItem.value = response.data?.total
        if (typeQuery.value !== currentRoute.value || (params?.limit && params?.limit !== LIMIT)) {
          store.setCurrentRoute(typeQuery.value)
        }

        if (response.data.items?.length < LIMIT && $state) {
          $state?.complete()
        } else if ($state) {
          $state?.loaded()
        }
      }
    } catch (error: any) {
      console.log('fetchListCasino', error?.response?.data?.message)
    } finally {
      load()
    }
  }

  const changePagination = ($state: any) => {
    page.value += 1
    fetchListCasino({}, $state)
  }

  const calculatePlaceholder = () => {
    const totalItems = listCasino.value?.length || 0
    let itemsPerRow
    if ($device.isDesktop) {
      itemsPerRow = 5
    } else {
      itemsPerRow = 3
    }

    const remainder = totalItems % itemsPerRow
    placeholderCount.value = remainder === 0 ? 0 : itemsPerRow - remainder
    store.setPlaceholderCount(placeholderCount.value)
  }
  watch(listCasino, () => {
    if (listCasino.value && listCasino.value.length > 0) {
      calculatePlaceholder()
    }
  })
  const fetchGameCasino = async () => {
    loading()
    try {
      const { data: response } = await $axios.get(`${LOBBY_CATEGORY}?alias=livecasino`)
      if (response.status === 'OK') {
        listCategoryCasino.value = response?.data || []
        store.setProviderCasino(response.data)
        filterProvider(listCategoryCasino.value)
      }
    } catch (error: any) {
      console.log('fetchGameCasino', error?.response?.data?.message)
    }
    load()
  }
  return {
    fetchListCasino,
    handleChangeProvider,
    initLoadData,
    changePagination,
    listProvider,
    currentProvider,
    listCasino,
    isLoading,
    titleCasino,
    totalItem,
    totalItemPerPage,
    EMPTY_CONTENT,
    resultProvider,
    calculatePlaceholder,
    placeholderCount,
    isLoadMore,
    currentCategory,
    typeQuery,
    partnerQuery,
    fetchGameCasino,
    listCategoryCasino,
    handleChangeCategory,
    page,
    reversedMapping,
    providerMapping
  }
}
